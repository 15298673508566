import React from "react"
import ProductPage from "../../layouts/product-page"

const model = {
  eng: {
    id: "10wifi",
    model: "P13 10´´  WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "10.1” （1280*800）IPS，Capacitive multi-touch",
    memory: "1 GB+16GB (compatible to 2+16GB)",
    dimension: "255.9*155.4*9.35mm，415g",
    cameras:
      "front: 2MP FF (compatible with 5MP FF) | rear: 2MP FF (compatible with 5MP FF)",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, GPS, FM(headset)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "4000mAh，Non-removable",
    cmf: "Raw Material",
    accesories:
      "default: Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Type case",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3fweeJLHQO0FYf2FXuIYgSHI7mqGx3bBr_d8Grl_x2BVZIiGKxN6PVRPIBvWznPFfJ-c3YVxwnczA9sfOuaLgtqjLgAnssxT7gFz5bf3LN0Mnk_mA3Tx2Wn9iNtvA4s_I5T_vXmMcbPz54tMa-TDro=d",
  },
  esp: {
    id: "10wifi",
    model: "P13 10´´  WiFi",
    os: "Android Oreo (Go edition)",
    chipset: "MT8321 Quad-core 1.3GHz",
    band: "N/A",
    display: "10.1” （1280*800）IPS，Capacitive multi-touch",
    memory: "1 GB+16GB (compatible to 2+16GB)",
    dimension: "255.9*155.4*9.35mm，415g",
    cameras:
      "front: 2MP FF (compatible with 5MP FF) | rear: 2MP FF (compatible with 5MP FF)",
    connectivity: "WiFi 802.11 b/g/n, BT4.2, GPS, FM(headset)",
    sensor: "G-sensor",
    interface: "Micro USB , 3.5mm jack , TF Card Slot, OTG",
    battery: "4000mAh，Non-removable",
    cmf: "Raw Material",
    accesories:
      "default: Charger 5V/1A, USB cable, QG, LCD Sticker | optional: Headset, Stand flip case, Type case",
    image:
      "https://lh3.googleusercontent.com/pw/ACtC-3fweeJLHQO0FYf2FXuIYgSHI7mqGx3bBr_d8Grl_x2BVZIiGKxN6PVRPIBvWznPFfJ-c3YVxwnczA9sfOuaLgtqjLgAnssxT7gFz5bf3LN0Mnk_mA3Tx2Wn9iNtvA4s_I5T_vXmMcbPz54tMa-TDro=d",
  },
}
export default () => <ProductPage model={model} />
